import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link, withRouter, Route } from 'react-router-dom';


import ArtworkList from '../artworks/page.js';
import ArtworkEdit from '../artwork/page.js';
import Blank from '../common/blank.js';
import DashboardPage from '../dashboard/page.js';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  toolbar: {
  	minHeight: theme.headerHeight
  },
}));




function Page() {

  const classes = useStyles();

  var pathname = window.location.pathname;
  var pathArray = pathname.split("/");

  var primary = pathArray[1];
  var secondary = pathArray[2];

 //  var Component = null;

 //  if (primary == "artworks") {

	// Component = ArtworkList;

 //  } else {

	// Component = Blank;

 //  }


  return (

      <div>       
        


      <Route exact path="/:user/artworks/:id" component={ArtworkList} />
		  <Route exact path="/:user/artwork/:section/:id/?" component={ArtworkEdit} />
      <Route exact path="/:user/dashboard/:id" component={DashboardPage} />



      </div>      

  );
}

export default withRouter(Page);
