import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';

import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';

import InputLabel from '@material-ui/core/InputLabel';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    flexWrap: 'wrap',
  },
  button: {
    // margin: theme.spacing(0),
  },  
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: "100%",
  },
  selectField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: "100%",
  },  
  checkbox: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },  
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: "none"
  },
  cardMedia: {
    paddingTop: '100%', // 16:9
    backgroundSize: "contain",
    backgroundColor: "#eee"

  },
  cardContent: {
    flexGrow: 1,
    padding: theme.spacing(2),
  }, 
  artwork: {
  	marginBottom: theme.spacing(2),
  	marginTop: theme.spacing(2)
  } , 
  section: {
  	marginBottom: theme.spacing(2),
  }

}));





const Basic = ({data}) => {

  const classes = useStyles();
  
  if (parseFloat(data.work_value) > 0) {

	  data.for_sale = true;

  }

  if (parseFloat(data.work_rental) > 0) {

	  data.for_rental = true;

  }

  if (parseFloat(data.work_trade_week) > 0 || parseFloat(data.work_trade_month) > 0) {

	  data.for_trade = true;

  }



  const [values, setValues] = React.useState(data);


 //  console.log(data);
 //  console.log(setValues);
 //  console.log(state);


  const handleChange = name => event => {

	setValues({ ...values, [name]: event.target.value });

  };

  const handleChecked = name => event => {


  	if (event.target.checked == true) {

		setValues({ ...values, [name]: true });

  	} else {

		setValues({ ...values, [name]: false });

  	}

  };


  return (

    <form className={classes.container} noValidate autoComplete="off">


    		<div className={classes.section}>

		      <FormControlLabel
		        control={
		          <Switch
		            checked={values.for_sale}
		            onChange={handleChecked('for_sale')}
		            value="value"
		            color="primary"
		          />
		        }
		        label="Available for sale"
		      />		      

		     <FormControl fullWidth className={classes.margin}>
		        <InputLabel htmlFor="adornment-amount">Purchase Price (CA$)</InputLabel>
		        <Input

		          id="adornment-amount"
		          value={values.work_value}
		          disabled={!values.for_sale}
		          onChange={handleChange('work_value')}
		          startAdornment={<InputAdornment position="start">$</InputAdornment>}
		        />
		      </FormControl>


		      </div>

    		<div className={classes.section}>

		      <FormControlLabel
		        control={
		          <Switch
		            checked={values.for_rental}
		            onChange={handleChecked('for_rental')}
		            value="value"
		            color="primary"
		          />
		        }
		        label="Available for 3 month rental"
		      />		      

		     <FormControl fullWidth className={classes.margin}>
		        <InputLabel htmlFor="adornment-amount">Rental Price / Month (CA$) <small>Min. 3 months</small></InputLabel>
		        <Input

		          id="adornment-amount"
		          value={values.work_rental}
		          disabled={!values.for_rental}
		          onChange={handleChange('work_rental')}
		          startAdornment={<InputAdornment position="start">$</InputAdornment>}
		        />
		      </FormControl>

		      </div>

    		<div className={classes.section}>

		      <FormControlLabel
		        control={
		          <Switch
		            checked={values.for_trade}
		            onChange={handleChecked('for_trade')}
		            value="value"
		            color="primary"
		          />
		        }
		        label="Available for trade access"
		      />		      




		        <Grid container spacing={3}>


			          <Grid item xs={6} sm={6} md={6}>


					     <FormControl fullWidth className={classes.margin}>
					        <InputLabel htmlFor="adornment-amount">Trade Price / Month (CA$)</InputLabel>
					        <Input

					          id="adornment-amount"
					          value={values.work_trade_month}
					          disabled={!values.for_trade}
					          onChange={handleChange('work_trade_month')}
					          startAdornment={<InputAdornment position="start">$</InputAdornment>}
					        />
					      </FormControl>


		              </Grid>
		           
			          <Grid item xs={6} sm={6} md={6}>


					     <FormControl fullWidth className={classes.margin}>
					        <InputLabel htmlFor="adornment-amount">Trade Price / Week (CA$)</InputLabel>
					        <Input

					          id="adornment-amount"
					          value={values.work_trade_week}
					          disabled={!values.for_trade}
					          onChange={handleChange('work_trade_week')}
					          startAdornment={<InputAdornment position="start">$</InputAdornment>}
					        />
					      </FormControl>

			          </Grid>

	              </Grid>





		      </div>


    </form>
  );
}

export default Basic;
