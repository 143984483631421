import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';

import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';

import InputLabel from '@material-ui/core/InputLabel';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Link, withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    flexWrap: 'wrap',
    padding: theme.spacing(3),
    border: "1px solid #eee"
  },
  button: {
    // margin: theme.spacing(0),
  },  
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: "100%",
  },
  selectField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: "100%",
  },  
  checkbox: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },  
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: "none"
  },
  cardMedia: {
    paddingTop: '100%', // 16:9
    backgroundSize: "contain",
    backgroundColor: "#eee"

  },
  cardContent: {
    flexGrow: 1,
    padding: theme.spacing(2),
  }, 
  artwork: {
  	marginBottom: theme.spacing(2),
  } , 
  thumbs: {
  	marginBottom: theme.spacing(2),
  },
  instructions: {
  	marginBottom: theme.spacing(2),  	
  },
  buttonContainer: {
  	marginTop: theme.spacing(3),  	
  }  
}));





const Basic = ({data, user}) => {

  const classes = useStyles();
  


  const [values, setValues] = React.useState(data);


 //  console.log(data);
 //  console.log(setValues);
 //  console.log(state);



  const handleChange = name => event => {

	setValues({ ...values, [name]: event.target.value });

  };

 //  const handleChecked = name => event => {

	// setValues({ ...values, [name]: event.target.checked });

 //  };


  return (

    <form className={classes.container} noValidate autoComplete="off">



	        <Grid container spacing={3}>


		          <Grid item xs={3} sm={3} md={3}>


			            <Typography variant="body2" component="h6" className={classes.instructions}>

							Use high-quality photos of your artwork (max 5mb each, JPG / PNG format).

			            </Typography>	


			            <Typography variant="body2" component="h6" className={classes.instructions}>

							The first image  should be a straight-on image of the work with no edges showing. This is used as the thumbnail and as part of the dynamic room rendering.

			            </Typography>	            

			            <Typography variant="body2" component="h6" className={classes.instructions}>

							Add additional views of the art, including side, back, detail, and installation views.

			            </Typography>	          


	              </Grid>
	           


		          <Grid item  xs={9} sm={9} md={9}>


			            <Typography spacing={0} variant="subtitle1" component="h6" className={classes.artwork}>

			              Artwork Images

			            </Typography>	



			    		{values.work_thumb.length > 0 &&




					        <Grid container spacing={3} className={classes.thumbs}>

					          {values.work_thumb.map((row, i) => (

					          <Grid item key={i} xs={6} sm={4} md={2}>

					            <Card className={classes.card}>
					              <CardMedia
					                className={classes.cardMedia}
					                image={row}
					                title="Image title"
					              />
				              </Card>
				              </Grid>
				           
				              ))}

				              </Grid>

			          	}


				      <Button variant="contained"

						component='label'
				      size="small" color="primary" className={classes.button}>
				        Add a new image
				           <input type="file"
				           accept="image/*"
				               style={{ display: "none" }} />
				      </Button>


				      <TextField
				        id="standard-name"
				        label="Name"
				        className={classes.textField}
				        value={values.work_title}
				        onChange={handleChange('work_title')}
				        margin="normal"
				      />


				      <TextField
				        id="standard-name"
				        label="Medium"
				        className={classes.textField}
				        value={values.medium_name}
				        onChange={handleChange('medium_name')}
				        margin="normal"
				      />

				     <FormControl
				        className={classes.selectField}
				          margin="normal"

				        >
				        <InputLabel htmlFor="difficulty-simple">Type</InputLabel>
				        <Select
				          value={values.type_id}
				          onChange={handleChange("type_id")}
				          inputProps={{
				            name: 'difficulty',
				            id: 'difficulty-simple',
				          }}
				        >
				          <MenuItem value={186}>Painting</MenuItem>
				          <MenuItem value={187}>Photography</MenuItem>
				          <MenuItem value={188}>Drawing / Print</MenuItem>
				          <MenuItem value={185}>Sculpture</MenuItem>
				        </Select>
				      </FormControl>

				        <Grid container spacing={3} >

				          <Grid item xs>

					      <TextField
					        label="Width (in)"
					        value={values.work_width}
					        onChange={handleChange('work_width')}
					        type="number"
					        className={classes.textField}
					        InputLabelProps={{
					          shrink: true,
					        }}
					        margin="normal"
					      />


					      </Grid>
			          		<Grid item xs>

					      <TextField
					        label="Length (in)"
					        value={values.work_length}
					        onChange={handleChange('work_length')}
					        type="number"
					        className={classes.textField}
					        InputLabelProps={{
					          shrink: true,
					        }}
					        margin="normal"
					      />


					      </Grid>
			          		<Grid item xs>

					      <TextField
					        label="Depth (in)"
					        value={values.work_depth}
					        onChange={handleChange('work_depth')}
					        type="number"
					        className={classes.textField}
					        InputLabelProps={{
					          shrink: true,
					        }}
					        margin="normal"
					      />		      

					      </Grid>
				      </Grid>

				      <div align="right" className={classes.buttonContainer}>

					      <Button align="right" variant="contained" component={Link} to={"/"+ user + "/artwork/details/" + values.work_id} color="primary" className={classes.button}>
					       	Next
					      </Button>

				      </div>


	              </Grid>


              </Grid>


    </form>
  );
}

export default Basic;
