import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import { BrowserRouter, Route, withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';



import Dashboard from "./components/common/dashboard";
import Head from "./components/common/head";

import logo from './logo.svg';
import './App.css';

import { Helmet } from 'react-helmet';




const theme = createMuiTheme({
  palette: {
    primary: {
      // light:  "#42b083",
      main:  "#42b083",
      dark: "#376261",
      contrastText: "#ffffff"
    },
    muted: {
      // light:  "#42b083",
      main:  "#E7EBEB",
      // dark: "#42b083",
      contrastText: "#376261"
    },

    secondary: green,
  },
  status: {
    danger: 'orange',
  },
  headerHeight: "48px",
  typography: {
    fontSize: 14,
    fontFamily: [
      'Futura LT W01 Medium',
      'Arial',
      // '-apple-system',
      // 'BlinkMacSystemFont',
      // '"Segoe UI"',
      // 'Roboto',
      // '"Helvetica Neue"',
      // 'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(',')    
  },
  overrides: {
    MuiInputLabel: { 
      root: { 
        fontSize: "1.25em", 
      },
    },
  }  
});

class App extends Component {
  render() {

  return (



  <BrowserRouter>

    <Head />

    <ThemeProvider theme={theme}>

      <CssBaseline />
      <Dashboard />

    </ThemeProvider>
  </BrowserRouter>

  );

  }

}

export default App;
