import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  toolbar: {
  	minHeight: theme.headerHeight
  },
}));



class Name extends Component {

	state = {
		"name": "Artist"
	};

	render(){

	  return (

	     	<span>{this.state.name}</span>

	  );

	}


	setPath = function(){

		return "https://api.partial.gallery/v1/info?user="+window.location.pathname.split("/")[1];
	}


    componentDidMount() {



	    fetch(this.setPath())
	        .then(res => res.json())
	        .then((data) => {
	            this.setState({name: data.result.fname});
	        })
	        .catch(console.log)

    }	

}

export default (Name);
