import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Link, withRouter } from 'react-router-dom';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  	minHeight: 0,
  },
  indicator: {
  	backgroundColor: theme.palette.primary.dark,
    opacity: 0
  },
  tab: {
  	minWidth: 0,
  	minHeight: 0,
  	// paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    marginRight: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderBottomWidth: "2px",
    borderBottomColor: "transparent",
    borderBottomStyle: "solid",
    borderTopWidth: "2px",
    borderTopColor: "transparent",
    borderTopStyle: "solid"        
    // fontFamily: "Futura LT W01 "
  },
   selected: {
    fontFamily: "Futura LT W01 Bold",
    borderBottomColor: theme.palette.primary.dark,
    },
}));

function Mainmenu() {


  const classes = useStyles();


  const menu = [

    {
      name: "My Artworks",
      url: "artworks",
      alt: "artwork",
      default: "all"
    },
    {
      name: "Dashboard",
      url: "dashboard",
      alt: "dashboard",
      default: "artwork"
    },

    {
      name: "My Profile",
      url: "account",
      default: "profile/bio"
    }

  ]

  function tabActive(pathname, menu) {



    for (var i = 0; i < menu.length; i++) {

      if (menu[i].url === pathname.split("/")[2] || menu[i].alt === pathname.split("/")[2]) {

        return i;

      }

    }

    if (pathname == "/") {

      return 0;

    }


  }

  return (
        <Tabs
          value={tabActive(window.location.pathname, menu)}
          classes={{
            indicator: classes.indicator,
          }}
          className={classes.root}
          // onChange={handleChange}
        >


          {menu.map((item, index) => (
            <Tab
              classes={{ selected: classes.selected }}
              className={classes.tab}
              key={item.url} 
              label={item.name} 
              component={Link} 
              to={"/"+window.location.pathname.split("/")[1]+"/"+item.url+"/"+item.default} />
          ))}


        </Tabs>
  );
}

export default withRouter(Mainmenu);