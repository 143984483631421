import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import Artwork from './artwork';
import Audience from './audience';
import Overview from './overview';
import OverviewLite from './overviewlite';
import Members from './members';
import Breakdown from './breakdown';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


function load(){



}

const styles = theme => ({
  container: {
  	paddingTop: theme.spacing(6),
  	// paddingBottom: theme.spacing(6),
  	// paddingLeft: theme.spacing(6),
  	marginLeft: 0
  },
  margin: {
  	marginTop: theme.spacing(3),
  },
  title: {
    fontFamily: "Futura LT W01 Bold",
  	marginBottom: theme.spacing(2)
  }
});



class Page extends Component {



    render() {

	    const { classes } = this.props;

		const section = window.location.pathname.split("/")[3];
		const user = window.location.pathname.split("/")[1];

		var overviewTitle = "Your overview";


		if (window.location.search.indexOf("collective") > -1) {

			overviewTitle = "Organization overview";

		}

        return (



			<Container maxWidth="md" className={classes.container}>

	            <Typography spacing={0} variant="h5" component="h5" className={classes.title}>

	              {section == "overview" ? overviewTitle : ""}
	              {section == "overviewlite" ? overviewTitle : ""}
	              {section == "artwork" ? "Tips to help you sell your art" : ""}
	              {section == "audience" ? "Your audience" : ""}
	              {section == "members" ? "Organization members" : ""}

	            </Typography>	


      			{this.state.stats == null &&

		            <Typography spacing={0} variant="h6" component="h6" className={classes.title}>
		              Loading ...
		            </Typography>

	        	}

      			{this.state.stats != null && section == "overview" &&

        	    	<Overview stats={this.state.stats} />





    	    	}

      			{this.state.stats != null && section == "overviewlite" &&

        	    	<OverviewLite stats={this.state.stats} />





    	    	}



				{this.state.stats != null && section == "overview" && (window.location.search.indexOf("collective") > -1) &&

					<Box  className={classes.margin}>

        	    		<Breakdown stats={this.state.stats} />

    	    		</Box>

				}

  				{this.state.data != null && section == "artwork" &&

		            <Artwork projects={this.state.data} user={user} />

    	    	}

  				{this.state.data != null && section == "audience" &&

		            <Audience data={this.state.data} />

    	    	}    


  				{this.state.members != null && section == "members" &&

		            <Members members={this.state.members} />

    	    	}     


            </Container>

        )
    }


    state = {
        data: null,
        artworks: null,
        stats: null,
        views_profile: null,
        ready: false
    };




	setPath = function(name){


		if (name == "views_artworks") {

			var path = "https://api.partial.gallery/v1/stats?user="+window.location.pathname.split("/")[1]+"&include=all";

		} else if (name == "members") {

			var path = "https://api.partial.gallery/v1/users?collective=ocadu";

		} else {

			var path = "https://api.partial.gallery/v1/artworks?user="+window.location.pathname.split("/")[1]+"&include=published";

		}

		return path;

		// return "https://api.partial.gallery/v1/info?user="+window.location.pathname.split("/")[1];



	}



	componentWillReceiveProps(nextProps){


// 		this.setPath();

// 		this.setState({ data: null, ready: false });

// 	    fetch(this.setPath(),
// // {
// //   headers: {
// //     'Cache-Control': 'no-cache'
// //   }
// // }

// 	    	)
// 	        .then(res => res.json())
// 	        .then((data) => {
// 	            this.setState({ data: data.result, ready: true })
// 	        })
// 	        .catch(console.log)

	}

    componentDidMount() {

		this.setPath();

		this.setState({ data: null, ready: false });

	    fetch(this.setPath(),
	    	)
	        .then(res => res.json())
	        .then((data) => {
	            this.setState({ data: data.result, ready: true })
	        })
	        .catch(console.log)



	    fetch(this.setPath("views_artworks"),
	    	)
	        .then(res => res.json())
	        .then((data) => {
	            this.setState({stats: data.result, ready: true })
	        })
	        .catch(console.log)




	    fetch(this.setPath("members"),
	    	)
	        .then(res => res.json())
	        .then((data) => {
	            this.setState({members: data.result, ready: true })
	        })
	        .catch(console.log)


    }
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);