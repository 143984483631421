import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Mainmenu from "./mainmenu";
import Name from "./name";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    backgroundColor: theme.palette.muted.main,
    color: theme.palette.muted.contrastText
    // padding: theme.spacing(2)
  },
  title: {
    flexGrow: 0,
    marginRight: theme.spacing(12),
    fontFamily: "Strato W01 Regular"
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="inherit" className={classes.appbar}>
        <Toolbar variant="dense">
          {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>*/}
          <Typography variant="h5" className={classes.title}>
            Hi <Name />!
          </Typography>
          <Mainmenu />
        </Toolbar>
      </AppBar>
    </div>
  );
}