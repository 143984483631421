import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import { Doughnut, Line } from 'react-chartjs-2';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import PersonIcon from '@material-ui/icons/Person';
import HelpIcon from '@material-ui/icons/Help';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ImageIcon from '@material-ui/icons/Image';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';

import SnackbarContent from '@material-ui/core/SnackbarContent';


import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  content: {
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
  	minHeight: theme.headerHeight
  },
  tabs: {
  },
  "selected": {
  	backgroundColor: theme.palette.primary.main,
  	color: "#fff !important"
  },
  "selected1": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff !important"
  },
  "selected2": {
    backgroundColor: "#AE8B2F",
    color: "#fff !important"
  },
  "selected3": {
    backgroundColor: "#9FD6D7",
    color: "#000 !important"
  },
  title: {
    fontFamily: "Futura LT W01 Bold",
  	marginTop: theme.spacing(4),
  	marginBottom: theme.spacing(2)
  },
  count: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0)
  },  
	headline: {
		marginBottom: theme.spacing(1)
	},
	subtitle: {
		marginBottom: theme.spacing(1)
	},
	list: {
		marginBottom: theme.spacing(2)
	},
  indicator:
  {
    backgroundColor: "rgba(255, 255, 255, 0.3)"
  },
	note  : {
	},
  box:{
    height: "400px"
  },
  snackbar: {
    textAlign: 'center',
    textTransform: "uppercase",
    width: "100%"
  },
  snackbarContainer: {
    marginBottom: theme.spacing(4),
  },
  icon: {
    fontSize: "2.5rem"
  },
  wrapper: {
    alignItems: "flex-start",
    fontSize: "1rem"

  }     
}));



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function List({stats}) {

	const data = {
	labels: [
    'Unspecified',
		'Facebook',
		'Twitter',
		'Website',
		'Instagram'
	],
	datasets: [{
		data: [100],
		backgroundColor: [
    '#eeeeee',
		'#376261',
		'#9FD6D7',
		'#32AE80',
		"#AE8B2F",
		],
		hoverBackgroundColor: [
    '#eeeeee',
		'#376261',
		'#9FD6D7',
		'#32AE80',
		"#AE8B2F",		
		]
	}]
	};

  const data2 = {
    labels: Object.keys(stats.views_artworks),
    datasets: [
      {
        label: 'Views',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data:  Object.values(stats.views_artworks),

      

      }
    ],
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                     userCallback: function(label, index, labels) {
                         // when the floored value is the same as the value we have a whole number
                         if (Math.floor(label) === label) {
                             return label;
                         }

                     }                    
                }
            }]
        }       
  };	


  const data3 = {
    labels: Object.keys(stats.views_profile),
    datasets: [
      {
        label: 'Views',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data:  Object.values(stats.views_profile),
    
      }
    ],
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                   userCallback: function(label, index, labels) {
                         // when the floored value is the same as the value we have a whole number
                         if (Math.floor(label) === label) {
                             return label;
                         }

                     }                      
                }
            }]
        }       
  };	

  const data4 = {
    labels: Object.keys(stats.uploads_artworks),
    datasets: [
      {
        label: 'Artworks Posted',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data:  Object.values(stats.uploads_artworks),

   
      }
    ],
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                   userCallback: function(label, index, labels) {
                         // when the floored value is the same as the value we have a whole number
                         if (Math.floor(label) === label) {
                             return label;
                         }

                     }                      
                }
            }]
        }       
  };  

  const classes = useStyles();
  const [value, setValue] = React.useState(0);


  function handleChange(event, newValue) {
    setValue(newValue);
  }


      // <SnackbarContent
      //   className={classes.snackbarContainer}
      //   align="center"
      //   classes={{
      //     message: classes.snackbar
      //   }}
      //   message={
      //     'We are still accumulating data to generate your overview. Please refer to the sample data below.'
      //   }
      // />

  return (



    <div className={classes.root}>




      <AppBar position="static" color="default">
        <Tabs
          value={value}
          className={classes.tabs}
            classes={{
              indicator: classes.indicator
            }}          
          onChange={handleChange}
          textColor="primary"
          variant="fullWidth"

        >
          <Tab

          	classes={{
          		selected: classes.selected1,
              wrapper: classes.wrapper
          	}}
          label={

              <React.Fragment>
                <Hidden smDown>
                <Typography spacing={0} variant="p" component="p" only='sm'>
                  Impressions
                </Typography>
                </Hidden>
                <Typography spacing={0} variant="h5" component="h5" className={classes.count}>
                  {stats.total_views_artworks.toLocaleString()}
                </Typography> 
              </React.Fragment>

          } icon={<VisibilityIcon className={classes.icon} />} />
          <Tab
            classes={{
              selected: classes.selected2,
              wrapper: classes.wrapper
            }}
          label={

              <React.Fragment>
                <Hidden smDown>
                <Typography spacing={0} variant="p" component="p" only='sm'>
                  Profile Visits
                </Typography>
                </Hidden>
                <Typography spacing={0} variant="h5" component="h5" className={classes.count}>
                  {stats.total_views_profile.toLocaleString()}
                </Typography> 
              </React.Fragment>

          } icon={<PersonIcon className={classes.icon} />} />          
          <Tab
          	classes={{
          		selected: classes.selected3,
              wrapper: classes.wrapper
          	}}
          label={

              <React.Fragment>
                <Hidden smDown>
                <Typography spacing={0} variant="p" component="p" only='sm'>
                  Artworks Posted
                </Typography>
                </Hidden>
                <Typography spacing={0} variant="h5" component="h5" className={classes.count}>
                  {stats.total_uploads_artworks.toLocaleString()}
                </Typography> 
              </React.Fragment>

          } icon={<ImageIcon className={classes.icon} />} />
        </Tabs>
      </AppBar>

	      <TabPanel value={value} index={0}>
	      	<div className={classes.box}>
				    <Line data={data2} options={{responsive: true, legend: { display: false }, scales: { yAxes: [{ ticks: { beginAtZero: true } }] } }}/>
			     </div>

	         </TabPanel>
           <TabPanel value={value} index={1}>

	      	  <div className={classes.box}>
				      <Line data={data3} options={{responsive: true, legend: { display: false }, scales: { yAxes: [{ ticks: { beginAtZero: true } }] } }}/>
			       </div>

         </TabPanel>
           <TabPanel value={value} index={2}>

            <div className={classes.box}>
              <Line data={data4} options={{responsive: true, legend: { display: false }, scales: { yAxes: [{ ticks: { beginAtZero: true } }] } }}/>
             </div>

         
         </TabPanel>         





    </div>


  );
}

export default withRouter(List);



      // <Typography spacing={0} variant="h5" component="h5" className={classes.title}>

      //   Your exposure

      // </Typography> 


      //   <Grid container spacing={3}>


      //       <Grid item xs={6} sm={6} md={6}>

      //         <div>
      //       <Doughnut data={data} options={{legend: { display: true, position: "left" }}}/>
      //     </div>


      //         </Grid>
           
      //       <Grid item xs={6} sm={6} md={6}>



      //       <Typography spacing={0} variant="h5" component="h5" align="center" className={classes.headline}>

      //         Nice work!

      //       </Typography> 


      //       <Typography spacing={0} variant="h6" component="h6" align="center" className={classes.subtitle}>

      //         Through Partial, you sent:

      //       </Typography> 


      //       <Typography spacing={0} variant="body1" component="p" align="center" className={classes.list} >


      //         0 people to your Instagram<br />
      //         0 people to your Facebook<br />
      //         0 people to your Website<br />
      //         0 people to your Twitter

      //       </Typography> 


      //       <Typography spacing={0} variant="h6" component="h6" align="center" className={classes.note}>

      //         Keep up your good work.

      //       </Typography> 


      //       </Grid>

      //     </Grid>
