import React from 'react';
import Header from "./header";
import Sidebar from "./sidebar";
import Main from "./main";

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



export default function Dashboard() {

  const classes = useStyles();

  return (

  	<Box>

	  	{/*<Header />*/}

  		<div className={classes.root}>

			{/*<Sidebar />*/}
			<Main />

		  </div>
	


  	</Box>


  );
}