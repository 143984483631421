import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';

import List from './list';





function load(){



}


class Page extends Component {


    render() {


		window.scrollTo(0, 0);

        return (

            <List projects={this.state.projects} ready={this.state.ready} user={this.state.user} />
        )
    }


    state = {
        projects: [],
        ready: false,
        user: ""
    };




	setPath = function(){


		return "https://api.partial.gallery/v1/artworks?user="+window.location.pathname.split("/")[1]+"&include="+window.location.pathname.split("/")[3];

	}

	componentWillReceiveProps(nextProps){

		this.setPath();

		this.setState({ projects: [], ready: false });

	    fetch(this.setPath())
	        .then(res => res.json())
	        .then((data) => {
	            this.setState({ projects: data.result, ready: true, user: window.location.pathname.split("/")[1] })
	        })
	        .catch(console.log)

	}

    componentDidMount() {

		this.setPath();

		this.setState({ projects: [], ready: false });

	    fetch(this.setPath())
	        .then(res => res.json())
	        .then((data) => {
	            this.setState({ projects: data.result, ready: true, user: window.location.pathname.split("/")[1] })
	        })
	        .catch(console.log)

    }
}

export default withRouter(Page);