import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import Link from '@material-ui/core/Link';
import { Link, withRouter } from 'react-router-dom';
// import { Link as RouterLink } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import SnackbarContent from '@material-ui/core/SnackbarContent';


const useStyles = makeStyles(theme => ({
  formContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // paddingLeft: theme.spacing(0),
    // paddingRight: theme.spacing(0),
  },
  table: {
    minWidth: 650,
  },	
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: "none"
  },
  cardMedia: {
    paddingTop: '100%', // 16:9
    backgroundSize: "contain",
    backgroundColor: "#fff",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "#eee",

  },
  cardContent: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  cardActions: {
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(0),
  },  
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  price: {
	fontFamily:"Futura LT W01 Bold",
  	margin: 0,
    marginRight: theme.spacing(1),

  },
  title: {
  	margin: 0,
  	marginBottom: theme.spacing(1),
  	lineHeight: 1.2
  },
  size: {
  	margin: 0,
  	marginBottom: theme.spacing(2)
  },
  unavailable: {
	fontFamily:"Futura LT W01 Bold",
  	color: "#FFC72A"
  },
  draft: {
	fontFamily:"Futura LT W01 Bold",
  	color: "#DF363C"
  },
  available: {
	fontFamily:"Futura LT W01 Bold",
  	color: theme.palette.primary.main
  },
  snackbar: {
  	textAlign: 'center',
  	textTransform: "uppercase",
  	width: "100%"
  }
}));


const Projects = ({projects, ready, user}) => {

	const classes = useStyles();

    return (

          <Container maxWidth="md" className={classes.formContainer}>

      			{projects.length == 0 && ready == true &&

			      <SnackbarContent
			        className={classes.snackbar}
			        align="center"
			        classes={{
			        	message: classes.snackbar
			        }}
			        message={
			          'No artworks are available in this section.'
			        }
			      />

      			}


	          <Grid container spacing={4}>

			          {projects.map(row => (


	              <Grid item key={row.work_id} xs={12} sm={6} md={4}>
	                <Card className={classes.card} component={Link} to={"/"+user+"/artwork/basic/"+row.work_id}>
	                  <CardMedia
	                    className={classes.cardMedia}
	                    image={row.work_thumb}
	                    title="Image title"

	                  />
	                  <CardContent className={classes.cardContent}>
	                    <Typography spacing={0} variant="h6" component="h2" className={classes.title}>
	                      {row.work_title}
	                    </Typography>
	                    <Typography spacing={0} variant="subtitle2" component="h6" className={classes.size}>
	                      {row.work_width}" x {row.work_length}"

	                      {row.work_depth ? " x " + row.work_depth+"\"" : ""}

	                    </Typography>	

					      {row.work_rental > 0 &&

	                    <Typography spacing={0} variant="subtitle2" component="h5" className={classes.price}>


		                      <span>Rent CA${row.work_rental}/mo</span>


	                    </Typography>		                                        

		                  }

					      {row.work_value > 0 &&

	                    <Typography spacing={0} variant="subtitle2" component="h5" className={classes.price}>

		                      <span>Price CA${row.work_value}</span>

	                    </Typography>	

		                  }


					      {/*{row.work_trade_month > 0 &&

	                    <Typography spacing={0} variant="subtitle2" component="h5" className={classes.price}>

		                      <span>Trade Month CA${row.work_trade_month}/mo</span>

	                    </Typography>	
	                    	                                        
		                  }

					      {row.work_trade_week > 0 &&

	                    <Typography spacing={0} variant="subtitle2" component="h5" className={classes.price}>

		                      <span>Trade Week CA${row.work_trade_week}/wk</span>

	                    </Typography>	
	                    	                                        
		                  }*/}


	                  </CardContent>
	                  <CardActions  className={classes.cardActions}>

					      {row.available == 1 &&

		                    <Typography spacing={0} variant="subtitle2" component="span" className={classes.available}>
		                    	Available
		                    </Typography>		

	                   		}


					      {row.available == 0 &&

		                    <Typography spacing={0} variant="subtitle2" component="span" className={classes.unavailable}>
		                    	Unavailable
		                    </Typography>		

	                   		}

					      {row.draft == 1 &&

		                    <Typography spacing={0} variant="subtitle2" component="span" className={classes.draft}>
		                    	Draft
		                    </Typography>		


	                		}


	                  </CardActions>
	                </Card>
	              </Grid>


			          ))}

	            
	          </Grid>

          </Container>

    )
};

export default Projects;


// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

// export default function WorkTable() {
//   const classes = useStyles();

//   return (

//   );
// }