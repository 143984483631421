import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';

import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';

import InputLabel from '@material-ui/core/InputLabel';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    flexWrap: 'wrap',
  },
  button: {
    // margin: theme.spacing(0),
  },  
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: "100%",
  },
  selectField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: "100%",
  },  
  checkbox: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },  
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: "none"
  },
  cardMedia: {
    paddingTop: '100%', // 16:9
    backgroundSize: "contain",
    backgroundColor: "#eee"

  },
  cardContent: {
    flexGrow: 1,
    padding: theme.spacing(2),
  }, 
  artwork: {
  	marginBottom: theme.spacing(2),
  	marginTop: theme.spacing(2)
  } , 
  thumbs: {
  	marginBottom: theme.spacing(2),
  } 
}));





const Basic = ({data}) => {

  const classes = useStyles();
  


  const [values, setValues] = React.useState(data);

  if (values.recognition == null) {

  	values.recognition = "";

  }


  if (values.tags == null) {

  	values.tags = "";

  }

 //  console.log(data);
 //  console.log(setValues);
 //  console.log(state);



  const handleChange = name => event => {

	setValues({ ...values, [name]: event.target.value });

  };

 //  const handleChecked = name => event => {

	// setValues({ ...values, [name]: event.target.checked });

 //  };


  return (

    <form className={classes.container} noValidate autoComplete="off">


		      <TextField
		        id="standard-name"
		        label="Worth Mentioning"
            placeholder="Ex. framing details, artist statement, etc."
		        className={classes.textField}
		        value={values.recognition}
		        onChange={handleChange('recognition')}
		        multiline
		        margin="normal"
		      />


		      <TextField
		        id="standard-name"
		        label="Where it's been"
            placeholder="Ex. exhibition history, galleries, film, etc."
		        className={classes.textField}
		        value={values.notes}
		        onChange={handleChange('notes')}
		        multiline
		        margin="normal"
		      />

		      <TextField
		        id="standard-name"
		        label="Tags"
            placeholder="Ex. abstracts, blue, minimalist, people, animals, bright, etc."
		        className={classes.textField}
		        value={values.tags}
		        onChange={handleChange('tags')}
		        multiline
		        margin="normal"
		      />


    </form>
  );
}

export default Basic;
