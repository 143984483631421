import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import Basic from './basic';
import Details from './details';
import Price from './price';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';


function load(){



}

const styles = theme => ({
  container: {
  	paddingTop: theme.spacing(4),
  	paddingBottom: theme.spacing(4)
  },
  title: {
    fontFamily: "Futura LT W01 Bold",
  	marginBottom: theme.spacing(1)
  }
});



class Page extends Component {



    render() {

	    const { classes } = this.props;

		const section = window.location.pathname.split("/")[3];
		const user = window.location.pathname.split("/")[1];

        return (



			<Container maxWidth="md" className={classes.container}>

	            <Typography spacing={0} variant="h5" component="h5" className={classes.title}>

	              {section == "basic" ? "Basic information" : ""}
	              {section == "details" ? "Additional details" : ""}
	              {section == "price" ? "Price" : ""}

	            </Typography>	

      			{this.state.data != null && section == "basic" &&

        	    	<Basic data={this.state.data} user={user} />

    	    	}

  				{this.state.data != null && section == "details" &&

		            <Details data={this.state.data} user={user} />

    	    	}

  				{this.state.data != null && section == "price" &&

		            <Price data={this.state.data} user={user} />

    	    	}     

            </Container>

        )
    }


    state = {
        data: null
    };




	setPath = function(){


		return "https://api.partial.gallery/v1/artwork?user="+window.location.pathname.split("/")[1]+"&work="+window.location.pathname.split("/")[4];

	}

	componentWillReceiveProps(nextProps){


		this.setPath();

		this.setState({ data: null });

	    fetch(this.setPath())
	        .then(res => res.json())
	        .then((data) => {
	            this.setState({ data: data.result })
	        })
	        .catch(console.log)

	}

    componentDidMount() {

		this.setPath();

		this.setState({ data: null });

	    fetch(this.setPath())
	        .then(res => res.json())
	        .then((data) => {
	            this.setState({ data: data.result })
	        })
	        .catch(console.log)

    }
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);