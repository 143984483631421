import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  toolbar: {
  	minHeight: theme.headerHeight
  },
}));



function List() {


  return (

      <div>Blank</div>

  );
}

export default withRouter(List);
