import React, { Components } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Collapse from '@material-ui/core/Collapse';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import Button from '@material-ui/core/Button';

import { Link, withRouter } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText
  },
  button:
  {
  	marginLeft: theme.spacing(4),
  	marginTop: theme.spacing(4),
  },
  toolbar: {
  	minHeight: theme.headerHeight
  },
  icon: {
  	fill: "white",
  	color: "white",
  	minWidth: 0,
  	paddingRight: theme.spacing(1),
  },
  iconGraphic: {
  	fontSize: theme.typography.fontSize*1.2
  },  
  list: {
  	paddingTop: theme.spacing(4)
  },
  item: {
  	paddingLeft: theme.spacing(4),
  	paddingRight: theme.spacing(4),
  	paddingTop: theme.spacing(0),
  	paddingBottom: theme.spacing(0),
  	textTransform: "uppercase",
  	opacity:0.8
  },
  nested: {
  	paddingLeft: theme.spacing(6),
  	paddingRight: theme.spacing(3),
  	paddingTop: theme.spacing(0),
  	paddingBottom: theme.spacing(0),
  	textTransform: "uppercase",
  },  
  text: {
  	// fontSize: theme.typography.fontSize*0.5,
  	fontFamily: "inherit",
	fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5 
  },
  text_disabled: {
  	// fontSize: theme.typography.fontSize*0.5,
  	fontFamily: "inherit",
	fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    opacity: 0.5
  },  
  title: {
  	marginLeft: theme.spacing(4),
  	textTransform: "uppercase"
  },
  tooltip: {
  	fontSize:theme.typography.fontSize*1,
  	backgroundColor: "black",
  	padding: theme.spacing(1),
  },
  selected: {
  	borderLeftWidth: theme.spacing(1),
  	paddingLeft: theme.spacing(3),
  	borderLeftColor: "#fff",
  	borderLeftStyle: "solid",
  	opacity:1,
	},
  disabled: {
  	opacity:0.3
	},	
  selected_parent: {
	fontFamily:"Futura LT W01 Bold",
  	opacity:1,

	},
  selected_nested: {
  	paddingLeft: theme.spacing(5),
  	borderLeftWidth: theme.spacing(1),
  	borderLeftColor: "#fff",
  	borderLeftStyle: "solid",
  	opacity:1,
	}	
}));


function NestedList() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  function handleClick() {
    setOpen(!open);
  }

  const user = window.location.pathname.split("/")[1];
  const parent = window.location.pathname.split("/")[2];
  const section = window.location.pathname.split("/")[3];
  var title = "Dashboard";

	var menu = [

		{
			name: "Your overview",
			url: "overview",
			disabled: true,
			tip: "We are accumulating more data for you. Please check back later."
		},
		{
			name: "Your artwork",
			url: "artwork",
			// tip: "We are accumulating more data for you. Please check back later."
		},
		{
			name: "Your audience",
			url: "audience",
			disabled: true,
			tip: "We are accumulating more data for you. Please check back later."
		},		
		// {
		// 	name: "Partial Overview",
		// 	url: "partial"
		// }
	]

  if (parent == "artworks") {

  		title = "My Artworks";
		menu = [

			{
				name: "All Artworks",
				url: "all"
			},
			{
				name: "Published",
				url: "published",
				icon: VisibilityOutlinedIcon,
				tip: "Only available published pieces will appear on the Marketplace. All Published pieces (available and unavailable) appear on your profile page."
			},
			{
				name: "Archived",
				url: "archived",
				icon: VisibilityOffOutlinedIcon,
				tip: "Archived works are hidden from the public and only visible to you in your Dashboard."
			},
			{
				name: "Draft",
				url: "draft",
				icon: CreateOutlinedIcon,
				tip: "Incomplete art listings that you have not yet published."	
			}

		]


  } else if (parent == "artwork") {


  		title = "My Artwork";
		menu = [


			{
				name: "Basic",
				url: "basic/"+window.location.pathname.split("/")[4]
			},
			{
				name: "Details",
				url: "details/"+window.location.pathname.split("/")[4]
			},
			{
				name: "Price",
				url: "price/"+window.location.pathname.split("/")[4]
			}

		]

  } else if (parent == "account") {

  		title = "My Profile";
		menu = [

			{
				name: "My profile",
				url: "profile",
				children: [
					{
						name: "About the artist",
						url: "bio"
					},
					{
						name: "Profile picture",
						url: "pic"
					},
					{
						name: "Banner image",
						url: "banner"
					},
					{
						name: "Questionnaire",
						url: "questionnaire"
					},
					{
						name: "In the studio",
						url: "studio"
					},
					{
						name: "CV",
						url: "cv"
					},
					{
						name: "Profile URL",
						url: "url"
					},																
				]
			},
			{
				name: "Settings",
				url: "settings",
				children: [
					{
						name: "Contact info",
						url: "contact"
					},
					{
						name: "Address info",
						url: "address"
					},
					{
						name: "Password",
						url: "password"
					}										
				]
			},
		]


  }


  function displaySub(children) {

  	return (


	  children.map((item, index) => (


		    <ListItem className={classes.nested} button key={index} exact="true"

				classes={{
					selected: classes.selected_nested
				}}

		    selected={window.location.pathname === "/"+user+"/"+parent+"/"+section+"/"+item.url} component={Link} to={"/"+user+"/"+parent+"/"+section+"/"+item.url}>
		      {/*<ListItemIcon className={classes.icon}>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
		      <ListItemText className={classes.text} primary={item.name} />
		    </ListItem>    

    	))

  	)


  }


  function displayIcon(icon){

  	if (icon == undefined) {

  		return false;

  	} else {


		// return element;

		const Icon = icon;

	  	return (


			<ListItemIcon className={classes.icon}>
			    <Icon classes={{
          			root: classes.iconGraphic
			    }} />
			</ListItemIcon>

		)


  	}


  }



  function displayTooltip(tip){

  	if (tip == undefined) {

  		return false;

  	} else {


		// return element;


	  	return (


		      <Tooltip title={tip} placement="left-start" classes={{
		      	tooltip: classes.tooltip
		      }}>
		          <HelpIcon className={classes.iconGraphic} />
		      </Tooltip>

		)


  	}


  }

  function display(item, index) {


	if (item.children != undefined)

	        return (

	        	<Box key={index}>

			      <ListItem
			      	key={index}
			      	className={classes.item}
			      	button
			      	selected={window.location.pathname.indexOf("/"+user+"/"+parent+"/"+item.url) > -1}
			      	component={Link}
			      	to={"/"+user+"/"+parent+"/"+item.url+"/"+item.children[0].url}
					classes={{
						selected: classes.selected_parent
					}} 
			      	>
			          <ListItemText
			          	disableTypography
			          	className={classes.text}
			          	primary={item.name}

			           />
			        {section == item.url ? <ExpandLess /> : <ExpandMore />}
			      </ListItem>
			      <Collapse in={section == item.url} timeout="auto" unmountOnExit>
			        
			      {displaySub(item.children)}

			      </Collapse>  	

		      	</Box> 


	        	)


	    var url = "/"+user+"/"+parent+"/"+item.url;

	    if (item.disabled == true) {


			return (

		        <ListItem 
		        	className={classes.item}
		        	button 
					key={index} 
					>

				      {displayIcon(item.icon)}


		          <ListItemText
		          	className={classes.text_disabled}
		          	primary={item.name} 
		          	/>

				      {displayTooltip(item.tip)}

		        </ListItem>    

	        )

	    } else {

			return (

		        <ListItem 
		        	className={classes.item}
		        	button 
					key={index} 
					exact="true" 
					selected={window.location.pathname.indexOf("/"+user+"/"+parent+"/"+item.url) > -1} 
					component={Link}


					to={url}


					classes={{
						selected: classes.selected
					}}
					>

				      {displayIcon(item.icon)}


		          <ListItemText
		          	className={classes.text}
		          	primary={item.name} 
		          	/>

				      {displayTooltip(item.tip)}

		        </ListItem>    

			)

	    }




  }


  return (


      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        className={classes.drawer}

      >
        <div className={classes.toolbar} />


        <List className={classes.list}>

        	<h2 className={classes.title}>{title}</h2>



          {menu.map((item, index) => (



          	display(item, index)




        

          ))}


          {parent == "artworks" ?

          		<Box>

			      <Button variant="contained" color="primary" className={classes.button}>
			        Add New
			      </Button>

		      	</Box>

          	: <span></span>}

          {parent == "artwork" ?

          		<Box>

			      <Button variant="contained" color="primary" className={classes.button}>
			        Save Work
			      </Button>

		      	</Box>

          	: <span></span>}


        </List>

      </Drawer>


  );
}


export default withRouter(NestedList);
