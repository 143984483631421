import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


class App extends Component {
  render() {
    return (
      <div>
        <Helmet>
         	 <title>Partial</title>
			<link type="text/css" rel="stylesheet" href="https://fast.fonts.net/cssapi/80c9802e-4ffd-48eb-82e8-559ff24aa054.css"/>
        </Helmet>
      </div>
    );
  }
}

export default App;