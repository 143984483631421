import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import {Doughnut} from 'react-chartjs-2';

function Donut({stats}){

	console.log(stats);

	const data = {
		labels: [
			'Sculpture',
			'Painting',
			'Photography',
			'Drawing / Print',
			'2D Works',
		],

		datasets: [{
			data: Object.values(stats.total_artwork_types),
			backgroundColor: [
			'#376261',
			'#9FD6D7',
			'#32AE80',
			"#AE8B2F",		
			"#999999",		
			],
			hoverBackgroundColor: [
			'#376261',
			'#9FD6D7',
			'#32AE80',
			"#AE8B2F",	
			"#999999",		
			]
		}]
	};

	const options = {
	    responsive: true,
	    legend: {
	        position: 'bottom',
	        labels: {
	        }
	    }

	}


    return (
      <div>
        <Doughnut data={data} options={options} />
      </div>
  );

};


export default withRouter(Donut);