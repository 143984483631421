import React, { Component } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import Link from '@material-ui/core/Link';
import { Link, withRouter } from 'react-router-dom';
// import { Link as RouterLink } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PersonIcon from '@material-ui/icons/Person';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ChatIcon from '@material-ui/icons/Chat';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';


const StyledTableCell = withStyles(theme => ({
  head: {
    fontSize: "1em",
    textTransform: "uppercase"
  }
}))(TableCell);



const useStyles = makeStyles(theme => ({
  formContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    // paddingLeft: theme.spacing(0),
    // paddingRight: theme.spacing(0),
  },
  table: {
    // minWidth: 650,
  },	
  icon: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  avatar: {
  	backgroundColor: theme.palette.primary.main
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: "none"
  },
  cardMedia: {
    paddingTop: '100%', // 16:9
    backgroundSize: "contain",
    backgroundColor: "#fff",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#eee",

  },
  cardContent: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  cardActions: {
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(0),
  },  
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  price: {
	fontFamily:"Futura LT W01 Bold",
  	margin: 0,
    marginRight: theme.spacing(1),

  },
  title: {
  	margin: 0,
  	marginBottom: theme.spacing(1),
  	lineHeight: 1.2
  },
  size: {
  	margin: 0,
  	marginBottom: theme.spacing(2)
  },
  unavailable: {
	fontFamily:"Futura LT W01 Bold",
  	color: "#FFC72A"
  },
  draft: {
	fontFamily:"Futura LT W01 Bold",
  	color: "#DF363C"
  },
  available: {
	fontFamily:"Futura LT W01 Bold",
  	color: theme.palette.primary.main
  },
  snackbar: {
  	textAlign: 'center',
  	textTransform: "uppercase",
  	width: "100%"
  },
  title: {
    fontFamily: "Futura LT W01 Bold",
  	marginTop: theme.spacing(4),
  	marginBottom: theme.spacing(2)
  },
  primary: {
    fontFamily: "Futura LT W01 Bold",
    textTransform: "uppercase"
  },
  secondary: {
  },
  tooltip: {
  	fontSize:theme.typography.fontSize*1,
  	backgroundColor: "black",
  	padding: theme.spacing(1),
  },  
}));

function profileUpdate(){

	window.parent.location = "https://www.partial.gallery/dashboard/account/profile/bio";

}

function tagUpdate(id){

	window.parent.location = "https://www.partial.gallery/dashboard/artworks/edit/"+id+"?m=details";

}

function priceUpdate(id){

	window.parent.location = "https://www.partial.gallery/dashboard/artworks/edit/"+id+"?m=sale";

}

const Projects = ({projects, ready, user}) => {

	const classes = useStyles();


    return (

          <div>





		    <Paper className={classes.root}>

		    <List className={classes.root}>
		      <ListItem>
		        <ListItemAvatar>
		          <Avatar className={classes.avatar}>
		            <MonetizationOnIcon  />
		          </Avatar>
		        </ListItemAvatar>
		        <ListItemText

		        	classes={{
		        		primary: classes.primary,
		        		secondary: classes.secondary
		        	}}

		         primary="Compare your price" secondary="With other relevant artworks on Partial to help them reach a wider audience" />
		      </ListItem>
		      <Divider />
		      <ListItem>
		        <ListItemAvatar>
		          <Avatar className={classes.avatar}>
		            <PersonIcon  />
		          </Avatar>
		        </ListItemAvatar>
		        <ListItemText

		        	classes={{
		        		primary: classes.primary,
		        		secondary: classes.secondary
		        	}}

		         primary="Update your profile " secondary="And let others know who you are as an artist to make your artworks stand" />
		      </ListItem>
		      <Divider />
		      <ListItem>
		        <ListItemAvatar>
		          <Avatar className={classes.avatar}>
		            <LocalOfferIcon  />
		          </Avatar>
		        </ListItemAvatar>
		        <ListItemText

		        	classes={{
		        		primary: classes.primary,
		        		secondary: classes.secondary
		        	}}

		         primary="Make sure your tags are relevant" secondary="And draw attention to the right audience and score a sale!" />
		      </ListItem>
		      <Divider />
		      <ListItem>
		        <ListItemAvatar>
		          <Avatar className={classes.avatar}>
		            <ChatIcon  />
		          </Avatar>
		        </ListItemAvatar>
		        <ListItemText

		        	classes={{
		        		primary: classes.primary,
		        		secondary: classes.secondary
		        	}}

		         primary="Add details" secondary="Of your artworks using our tags and help your audience imagine them in real context." />
		      </ListItem>		      
		    </List>

		    </Paper>

		    <Typography spacing={0} variant="h5" component="h5" className={classes.title}>

		      Your artwork

		    </Typography>	

      			{projects.length == 0 && ready == true &&

			      <SnackbarContent
			        className={classes.snackbar}
			        align="center"
			        classes={{
			        	message: classes.snackbar
			        }}
			        message={
			          'No artwork available here.'
			        }
			      />

      			}


		    <Paper className={classes.root}>
		      <Table className={classes.table}>


		      	<Hidden smDown>

			        <TableHead>
			          <TableRow>
			            <StyledTableCell >Thumbnail</StyledTableCell>
			            <StyledTableCell >Title</StyledTableCell>
			            <StyledTableCell align="center">Views</StyledTableCell>
			            <StyledTableCell align="center">Suggestions</StyledTableCell>
			            <StyledTableCell align="center">Action</StyledTableCell>
			          </TableRow>
			        </TableHead>

		        </Hidden>
		        
		        <TableBody>

			          {projects.map(row => (



		            <TableRow key={row.work_id}>

		              <TableCell component="th" scope="row">
		                  <CardMedia
		                    className={classes.cardMedia}
		                    image={row.work_thumb}
		                    title="Image title"

		                  />
		              </TableCell>

		              <TableCell component="th" scope="row">
		                	{row.work_title}
		              </TableCell>
	              
		              <TableCell align="center">

		                	{row.views == null &&

		                		<span>(collecting)</span>

		                	}

		                	{row.views != null &&

		                		<span>{row.views}</span>

		                	}		                	

	              		</TableCell>
		              <TableCell align="center">

						    <Tooltip classes={{tooltip: classes.tooltip}} title="Compare your prices">

								<MonetizationOnIcon

								onClick={() => priceUpdate(row.work_id)}

								color="primary" className={classes.icon} />

							</Tooltip>

							{row.bio == null &&

							    <Tooltip classes={{tooltip: classes.tooltip}} title="Update your profile">

									<PersonIcon

									onClick={profileUpdate}

									color="primary" className={classes.icon} />

								</Tooltip>

							}


							{row.tags == null || row.tags.length < 10 &&


						    <Tooltip classes={{tooltip: classes.tooltip}} title="Check your tags">

								<LocalOfferIcon

								onClick={() => tagUpdate(row.work_id)}

								color="primary" className={classes.icon} />


							</Tooltip>


							}

							{row.notes == null || row.notes.length < 10 &&

							    <Tooltip classes={{tooltip: classes.tooltip}} title="Add details">

									<ChatIcon

									onClick={() => tagUpdate(row.work_id)}
									color="primary" className={classes.icon} />

								</Tooltip>

							}

		              </TableCell>
		              <TableCell align="center">

					      <Button
					      	variant="contained"
					      	color="primary" 
					      	className={classes.button}
					      	component="a"
					      	size="small"

					      	target="_parent"
					      	href={"https://www.partial.gallery/dashboard/artworks/edit/"+row.work_id}
				      		>
					        Review Work
					      </Button>

	              		</TableCell>		              
		            </TableRow>




			          ))}

		        </TableBody>
		      </Table>




		    </Paper>    
           
          </div>

    )
};

export default withRouter(Projects);


// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

// export default function WorkTable() {
//   const classes = useStyles();

//   return (

//   );
// }