import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Page from "./page";


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  toolbar: {
  	minHeight: theme.headerHeight
  },
}));



export default function Main() {

  const classes = useStyles();

  return (

      <main className={classes.content}>
        {/*<div className={classes.toolbar} />*/}


        <Page></Page>

      </main>      

  );
}